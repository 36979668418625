import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  TextField,
  Collapse,
} from '@material-ui/core';

import { withFormik } from 'formik';

import Checkbox from '@pro/web-common/components/checkbox';


const CheckInPageEditor = React.memo(({ isOpened, isSubmitDisabled, ...formikProps }) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = formikProps;

  useEffect(() => {
    if (isOpened) {
      resetForm();
    }
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <form onSubmit={handleSubmit}>
        <Box
          mt={2}
          mb={2}
        >
          <Checkbox
            id="autoCheckout"
            name="autoCheckout"
            label="Check out automatically at end of day."
            value={values.autoCheckout}
            onChange={({ target: { checked } }) => setFieldValue('autoCheckout', checked)}
          />
        </Box>

        <Box
          mt={2}
          mb={2}
        >
          <Checkbox
            id="collectData"
            name="collectData"
            label="Collect personal user information."
            value={values.collectData}
            onChange={({ target: { checked } }) => setFieldValue('collectData', checked)}
          />
        </Box>

        <Box mb={2}>
          <TextField
            variant="outlined"
            fullWidth
            id="infoText"
            label="Info text"
            placeholder="Info text"
            name="infoText"
            value={values.infoText}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={3}
          />
        </Box>

        <Box
          mt={1}
          textAlign="right"
        >
          <Button
            type="submit"
            size="small"
            color="primary"
            variant="contained"
            disabled={isSubmitDisabled}
          >
            Save
          </Button>
        </Box>
      </form>
    </Collapse>
  );
});

CheckInPageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
};

CheckInPageEditor.defaultProps = {};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      infoText = '',
      autoCheckout = false,
      collectData = true,
    } = initialValues || {};

    const values = {
      infoText,
      autoCheckout,
      collectData,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values),
  enableReinitialize: true,
})(CheckInPageEditor);
