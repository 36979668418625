import { createMuiTheme } from '@material-ui/core/styles';

import defaultTheme from '@pro/web-common/theme';


const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      light: '#B4C8C6',
      main: '#253D3B',
      additional: '#538A84',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Fira Sans',
  },
});

export const LOGO_WIDTH = '200px';

export default theme;
