import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import AppStore from 'assets/images/enterprise/appStore289287056.png';

import EnterpriseItem from './enterprise-item';


const EnterpriseSection = React.memo(() => (
  <Box
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Your own app in the app stores!
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
                Enterprise Apps. Your own app, in your own name.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <EnterpriseItem
            image={AppStore}
            title="Guest Experience App."
            subtitle="Your app in your own name."
            alt="#guestexperience Guest App No Code Touchless Tech"
            description={(
              <>
                <p>We can &apos;save out&apos; your app content from ConciergePro, add your app name and logo and publish your own app in the stores.</p>
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <EnterpriseItem
            title="How to upgrade to your own Enterprise App"
            subtitle="For larger businesses with their own app store accounts"
            description={(
              <>
                <p>First, sign up to ConciergePro and create your guest experience app (or ask us to help).</p>
                <p>Once you are happy, email us at team@conciergepro.co. We&apos;ll take it from there. </p>
                <p>The costs are the same as a subscription to ConciergePro, plus a one off set up fee.</p>
              </>
            )}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


EnterpriseSection.propTypes = {};

EnterpriseSection.defaultProps = {};


export default EnterpriseSection;
