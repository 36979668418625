import React from 'react';
import { NavHashLink } from 'react-router-hash-link';

import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Link,
} from '@material-ui/core';

import { STORE_LINKS } from 'constants/app';

import SectionImage from 'assets/images/home/conciergeProStartNowPromo.jpg';

import { styles } from './styles';


const StartNowSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      bgcolor="primary.light"
      pt={10}
      pb={10}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h3"
            >
              Start now.
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h1"
              >
                Create a &apos;touchless&apos; guest experience app for your Hotel, Resort, Attraction or Tour.
              </Typography>
            </Box>

            <Typography>
            Keep guests engaged, informed and intrigued before, during and after their stay.<br />
              Increase confidence, maximise uplift and repeat bookings by providing everything your guests need in one place, their own mobile phones.<br />
              ConciergePro changes into your own fully branded and customised guest experience app.<br />
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                Paperless guest information, contact trace, menus, push notifications, direct booking, social media pages, partner information, vouchers, maps, local treats + much more!
              </Box>
            </Typography>

            <Box
              mt={3}
              mb={[4, 0]}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                to="#pricing"
                smooth
                offset={-64}
                component={NavHashLink}
              >
                Pick A Plan
              </Button>

              <Typography component="span">
                FREE for 30 days!
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

            <Box mt={4}>
              {STORE_LINKS.map(({ id, href, icon }) => (
                <Link
                  key={id}
                  href={href}
                  className={classes.storeLink}
                >
                  <img
                    src={icon}
                    alt={id}
                  />
                </Link>
              ))}
            </Box>
            <Box
              mt={1}
              display="block"
              component="span"
              fontWeight="fontWeightBold"
            >
              ConciergePro app is free for your guests, forever.
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


StartNowSection.propTypes = {};

StartNowSection.defaultProps = {};


export default StartNowSection;
