import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import Easy1 from 'assets/images/123/Brand_Your_Guest_App.png';
import Easy2 from 'assets/images/123/Add_Your_Hotel_Resort_Attraction_Tour.png';
import Easy3 from 'assets/images/123/Tell_Your_Guests_QR_Code.png';
import Easy4 from 'assets/images/123/App_Different_Kettle_Of_Fish.png';

import CaseItem from './case-item';


const CasesSection = React.memo(() => (
  <Box
    bgcolor="primary.light"
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Easy as 1, 2, 3
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
                Start by signing up for free, and confirming your login details.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy1}
            title="Step 1"
            subtitle="Once signed up, add your branding!"
            alt="Touchless app, paperless app, hotels and resorts"
            description={(
              <>
                Configure your brand - add your logo, choose your fonts and add colors.
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy2}
            title="Step 2"
            subtitle="Add your destination Hotel, Resort, Attraction or Tour."
            alt="Guest Experience App"
            description={(
              <>
                Add pages to your app, images, content and links. We&apos;re here 24/7 to help if you need it.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy3}
            title="Step 3"
            subtitle="Let your guests know!"
            alt="Your guests just need a QR code"
            description={(
              <>
                Tell your guests about your new app! Add the QR code, link or search terms to emails and website - we provide some print ready artwork too for labels and POS.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy4}
            title="It&apos;s a completely different kettle of fish"
            subtitle="We love fish, but this is different"
            alt="Easy to use app builder no code required"
            description={(
              <>
                Communicate direct with guests that are checked-in or just booked. Incentivise checked-out guests to rebook direct in app.
              </>
            )}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


CasesSection.propTypes = {};

CasesSection.defaultProps = {};


export default CasesSection;
