import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import Promo1 from 'assets/images/promo/designerHelp.png';

import PromoItem from './promo-item';


const PromoSection = React.memo(() => (
  <Box
    bgcolor="primary.light"
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Help when you need it.
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
                Building your own Guest Experience app is now easier than ever, but we understand resources are spread thin right now. We&apos;re here to help.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PromoItem
            description={(
              <>
                <p>We designed and built our own admin system, so we know how easy it is to create your own guest experience app, but we also understand resources are really tight right now.</p>
                <p>So, if you&apos;d like - we&apos;ll create your app for you completely FREE. Just SIGN UP and let us know you&apos;d like some help. We&apos;ll get everything sorted.</p>
                <p>We&apos;ll also make sure you have training support content and access to our legendary chat support - available 24/7.</p>
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PromoItem
            image={Promo1}
          />
        </Grid>

      </Grid>
    </Container>
  </Box>
));


PromoSection.propTypes = {};

PromoSection.defaultProps = {};


export default PromoSection;
