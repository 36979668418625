import React from 'react';

import {
  Container,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';

import SectionImage from 'assets/images/home/build-manual-app-hero.png';

import { styles } from './styles';


const BuildManualSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      pt={6}
      pb={4}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              Your own guest experience app - no code required
            </Typography>

            <Box
              fontSize="body1.fontSize"
              className={classes.list}
            >
              <ul>
                <li>Add your logo, images, colors and fonts</li>
                <li>Include multiple Properties (hotels, resorts, attractions, tours)</li>
                <li>Paperless guest information section</li>
                <li>Translate into any language*</li>
                <li>Use powerful messaging with push notifications</li>
                <li><strong>BRAND NEW Menu builder!</strong></li>
                <li>Emergency and facility communications/updates</li>
                <li>Include partner info and promo vouchers</li>
                <li>Local places with maps and directions</li>
                <li>All your social media feeds in one place</li>
                <li>Promote direct bookings and reviews</li>
                <li><strong>Built in Contact Trace notifications</strong></li>
                <li>Update 24/7</li>
              </ul>
            </Box>

            <Typography>
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                A whole new channel to intrigue, engage and enhance the guest experience.
              </Box>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

            <Box
              mt={4}
              textAlign="left"
            >
              <Typography
                gutterBottom
                variant="h4"
              >
                Why ConciergePro.co?
              </Typography>

              <Box
                className={classes.list}
                fontSize="body1.fontSize"
              >
                <ul>
                  <li>FREE 30 day trial</li>
                  <li>Easy, no code set up</li>
                  <li>Mobile friendly admin</li>
                  <li>Simple pricing</li>
                  <li>Monthly contract</li>
                  <li>Feature packed</li>
                  <li>No GDPR impact</li>
                  <li>Unrivalled support 24/7</li>
                </ul>
              </Box>
            </Box>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
});


BuildManualSection.propTypes = {};

BuildManualSection.defaultProps = {};


export default BuildManualSection;
