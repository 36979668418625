import React from 'react';

import {
  Container,
  Grid,
  Box,
} from '@material-ui/core';

import Loads from 'assets/images/home/loadsmore.png';
import Open from 'assets/images/home/opentable_logo.png';
import Trip from 'assets/images/home/tripadvisor_logo.png';
import Feefo from 'assets/images/home/feefo-logo.svg';
import Chat from 'assets/images/home/chatbotlabs_logo.png';
import Zen from 'assets/images/home/zendesk_logo.png';
import Booking from 'assets/images/home/booking_logo.png';
import Here from 'assets/images/home/here_logo.png';


const LogosSection = React.memo(() => (
  <Box
    bgcolor="#FFFFFF"
    pt={8}
    pb={8}
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={8}
      >
        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Trip}
            alt="Trip Advisor integration"
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Here}
            alt="Here Technologies integration"
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Open}
            alt="Open Table integration"
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <Box
            display="flex"
            width={1}
            justifyContent="center"
          >
            <Box width={[60, 80]}>
              <img
                src={Feefo}
                alt="logo"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        spacing={8}
      >
        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Chat}
            alt="Feefo integration"
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Zen}
            alt="logo"
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Booking}
            alt="logo"
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Loads}
            alt="logo"
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


LogosSection.propTypes = {};

LogosSection.defaultProps = {};


export default LogosSection;
