const config = {
  firebase: {
    apiKey: 'AIzaSyCi-NoaS4MgA0GrxCk7Awa3Fk5XADQerXg',
    authDomain: 'conciergepro-6f21a.firebaseapp.com',
    databaseURL: 'https://conciergepro-6f21a.firebaseio.com',
    projectId: 'conciergepro-6f21a',
    storageBucket: 'conciergepro-6f21a.appspot.com',
    messagingSenderId: '665515207363',
    appId: '1:665515207363:web:346fcb4aa5577e6aa51076',
    measurementId: 'G-WLMRSHD18Q',
  },
  stripePublicAPIKey: 'pk_live_17Ci2apd5ch0b1XT1jJ55Ls400m9N7ApDo',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDGRrlqkPcPxNJcYLcMfT-H2ouyCqsH4RM&libraries=places,geometry',
  },
};


export default config;
