import ImagePageEditor from '@pro/web-common/components/image-page-editor';
import UrlPageEditor from '@pro/web-common/components/url-page-editor';
import PhoneNumberPageEditor from '@pro/web-common/components/phone-number-page-editor';
import RtfPageEditor from '@pro/web-common/components/rtf-page-editor';
import ManualPageEditor from '@pro/web-common/components/manual-page-editor';
import DirectoryPageEditor from '@pro/web-common/components/directory-page-editor';
import FacebookPageEditor from '@pro/web-common/components/facebook-page-editor';
import MenuPageEditor from '@pro/web-common/components/menu-page-editor';
import LocalDirectoryPageEditor from '@pro/web-common/components/local-directory-page-editor';
import CheckInPageEditor from '@pro/web-common/components/check-in-page-editor';
import TimetablePageEditor from '@pro/web-common/components/timetable-page-editor';
// import MagazinePageType from '@pro/web-common/components/magazine-page-editor';

import { getPageProps } from '@pro/web-common/utils/product';


export const PAGE_TYPE = {
  IMAGE: {
    id: 'image',
    editor: ImagePageEditor,
    label: 'Image',
    limit: 1,
    infoKey: 'imageSection',
    noContentText: 'You have not yet added any content to the Home Page, please use the edit icon next to \'Home\' to add images',
  },
  MANUAL: {
    id: 'manual',
    editor: ManualPageEditor,
    label: 'Guest Info',
    limit: 1,
    infoKey: 'manualSection',
    noContentText: null,
  },
  MESSAGES: {
    id: 'messages',
    editor: null,
    label: 'Messages',
    limit: 1,
    infoKey: 'messagesSection',
    noContentText: null,
  },
  PHONE_NUMBER: {
    id: 'phoneNumber',
    editor: PhoneNumberPageEditor,
    label: 'Phone Number',
    limit: 1,
    infoKey: 'phoneNumberSection',
    noContentText: null,
  },
  URL: {
    id: 'url',
    editor: UrlPageEditor,
    label: 'Website (https)',
    infoKey: 'urlSection',
    noContentText: null,
  },
  RTF: {
    id: 'rtf',
    editor: RtfPageEditor,
    label: 'Rich Text Format',
    infoKey: 'rtfSection',
    noContentText: null,
  },
  DIRECTORY: {
    id: 'directory',
    editor: DirectoryPageEditor,
    label: 'Contact Directory',
    limit: 1,
    infoKey: 'directorySection',
    noContentText: null,
  },
  FACEBOOK: {
    id: 'facebook',
    editor: FacebookPageEditor,
    label: 'Facebook',
    limit: 1,
    infoKey: 'facebookSection',
    noContentText: null,
  },
  MENU: {
    id: 'menu',
    editor: MenuPageEditor,
    label: 'Menu',
    limit: 4,
    infoKey: 'menuSection',
    noContentText: null,
  },
  LOCAL_DIRECTORY: {
    id: 'localDirectory',
    editor: LocalDirectoryPageEditor,
    label: 'Directory Page',
    limit: 1,
    infoKey: 'localDirectorySection',
    noContentText: null,
  },
  CHECK_IN: {
    id: 'checkIn',
    editor: CheckInPageEditor,
    label: 'Check In/Out',
    limit: 1,
    infoKey: 'checkInSection',
    noContentText: null,
  },
  TIMETABLE: {
    id: 'timetable',
    editor: TimetablePageEditor,
    label: 'Timetable',
    infoKey: 'timetableSection',
    noContentText: null,
  },
  // MAGAZINE: {
  // id: 'magazine',
  // editor: MagazinePageType,
  // label: 'Magazine',
  // limit: 1,
  // infoKey: 'magazineSection',
  // noContentText: null,
  // },
};

export const DEFAULT_APP_ICONS = {
  HOME: 'Home',
  MANUAL: 'FileCopy',
  MESSAGES: 'Message',
  WEBSITE: 'Language',
};


const HOME_PAGE = {
  ...getPageProps({ iconId: DEFAULT_APP_ICONS.HOME, pageTypeId: PAGE_TYPE.IMAGE.id, label: 'Home' }),
  isDeletable: false,
};

const MANUAL_PAGE = {
  ...getPageProps({ iconId: DEFAULT_APP_ICONS.MANUAL, pageTypeId: PAGE_TYPE.MANUAL.id, label: 'Guest Info' }),
  isCopyAvailable: true,
};

const MESSAGES_PAGE = {
  ...getPageProps({ iconId: DEFAULT_APP_ICONS.MESSAGES, pageTypeId: PAGE_TYPE.MESSAGES.id, label: 'Messages' }),
  isContentEditable: false,
  isDeletable: false,
};

const WEBSITE_PAGE = {
  ...getPageProps({ iconId: DEFAULT_APP_ICONS.WEBSITE, pageTypeId: PAGE_TYPE.URL.id, label: 'Website' }),
  isDeletable: false,
};

export const DEFAULT_PAGES = [
  HOME_PAGE,
  MANUAL_PAGE,
  MESSAGES_PAGE,
  WEBSITE_PAGE,
];

export const MIN_PAGES_PER_PRODUCT = 4;
export const isTestVersion = false;
export const withLocationSetup = true;
