import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Container, Grid, Typography, Box, Button } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';

import userActions from '@pro/web-common/core/user/actions';

import { styles } from './styles';


const DemoIntro = React.memo(({ signOut }) => {
  const classes = styles();

  const onSignUp = useCallback((event) => {
    event.preventDefault();
    signOut({ withRedirectToSignUp: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <PageTitle
        title="ConciergePro Demo"
        subtitle="Check out how simple ConciergePro is for you and your guests ..."
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="How guests will see your app in ConciergePro"
            subtitle="This video shows what happens when your guest first scans your QR code."
          />

          <Box className={classes.container}>
            <iframe
              title="youtube video"
              src="https://www.youtube.com/embed/gxkGJmnkcis"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Typography>
            If they already have ConciergePro on their phones, it will immediately open your app.
            If not, the clever QR will take then to the app store, immediately download ConciergePro and automatically open your venue experience app.
            Guests can also search using keywords.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="How you manage content in your app"
            subtitle="This is a quick overview of the web admin - where you manage app content"
          />

          <Typography>
            In admin you can easily manage all your content and messaging for your app.
            All new or edited content is immediately available in your app, you can even manage all this on your mobile device.
            Try the DEMO below or sign up now!
          </Typography>
        </Grid>


        <Grid
          item
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
              ml={2}
              mr={2}
            >
              <Button
                component={NavLink}
                color="inherit"
                variant="outlined"
                to="/configure-brand"
              >
                Demo Admin
              </Button>
            </Box>

            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

DemoIntro.propTypes = {
  signOut: PropTypes.func.isRequired,
};

DemoIntro.defaultProps = {};

const mapDispatchToProps = {
  signOut: userActions.signOut,
};


export default connect(null, mapDispatchToProps)(DemoIntro);
