import { generateId } from '@pro/web-common/utils';


export const getSectionDefaultProps = () => ({
  id: generateId(),
  title: '',
  html: '',
  image: null,
  isVisible: true,
});
