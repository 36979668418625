import BusCard from 'assets/images/artworks/BusCard.png';
import A5 from 'assets/images/artworks/A5.png';
import A4 from 'assets/images/artworks/A4.png';
import A4L from 'assets/images/artworks/A4Labels24.png';


export const ARTWORKS = [
  {
    id: 1,
    artwork: BusCard,
    dimensions: {
      width: 240,
      height: 155,
    },
    qrPositions: [
      {
        x: 14,
        y: 44,
        width: 98,
        height: 98,
      },
    ],
  },
  {
    id: 2,
    artwork: A5,
    dimensions: {
      width: 420,
      height: 595,
    },
    qrPositions: [
      {
        x: 20,
        y: 382,
        width: 192,
        height: 192,
      },
    ],
  },
  {
    id: 3,
    artwork: A4,
    dimensions: {
      width: 842,
      height: 595,
    },
    qrPositions: [
      {
        x: 20,
        y: 382,
        width: 192,
        height: 192,
      },
    ],
  },
  {
    id: 4,
    artwork: A4L,
    dimensions: {
      width: 595,
      height: 842,
    },
    qrPositions: [
      {
        x: 23,
        y: 48,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 144,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 240,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 336,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 432,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 528,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 624,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 720,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 48,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 144,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 240,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 336,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 432,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 528,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 624,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 720,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 48,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 144,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 240,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 336,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 432,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 528,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 624,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 720,
        width: 76,
        height: 76,
      },
    ],
  },
];
