import React from 'react';
import { Helmet } from 'react-helmet';


export default {
  default: (
    <Helmet
      defaultTitle="ConciergePro"
    >
      <meta
        name="keywords"
        content="guest, hotel, resort, experience, touchless, documentation, direct, booking, safety, F&B, holiday, loyalty"
      />
      <meta
        name="description"
        content="Improve hotel guest experience, increase revenue, direct bookings and loyalty with custom branded hotel app. Contactless technology services for guests, enhance guest experience and increase safety. Communicate with in app messaging."
      />
    </Helmet>
  ),
  home: (
    <Helmet>
      <title>ConciergePro - Guest Experience App for Hotels, Resorts and Attractions</title>
      <meta
        name="keywords"
        content="guest, hotel, resort, experience, touchless, documentation, direct, booking, safety, F&B, holiday, loyalty"
      />
      <meta
        name="description"
        content="Improve hotel guest experience, increase revenue, direct bookings and loyalty with custom branded hotel app. Contactless technology services for guests, enhance guest experience and increase safety. Communicate with in app messaging."
      />
    </Helmet>
  ),
  signUp: (
    <Helmet>
      <title>SignUp 30 Days Free Guest Experience App</title>
      <meta
        name="keywords"
        content="create, guest, app, experience, sign, up, no, code"
      />
      <meta
        name="description"
        content="Create guest experience app, increase direct bookings. Communicate with push messaging."
      />
    </Helmet>
  ),
  logIn: (
    <Helmet>
      <title>Login to manage your guest app content and messaging</title>
      <meta
        name="keywords"
        content="guest, hotel, resort, experience, direct, booking, safety, F&B, holiday, loyalty, staycation, boutique"
      />
      <meta
        name="description"
        content="Login to manage added value content, guest messaging, in room information."
      />
    </Helmet>
  ),
  forgotPassword: (
    <Helmet>
      <title>Password recovery for ConciergePro</title>
      <meta
        name="keywords"
        content="password, recovery, reset, guest, app, boutique, hotel, resort, staycation, camp, site"
      />
      <meta
        name="description"
        content="Recover your password here to admin your Guest Experience app."
      />
    </Helmet>
  ),
  aboutUs: (
    <Helmet>
      <title>About Us</title>
      <meta
        name="description"
        content="About Helmet description"
      />
    </Helmet>
  ),
  support: (
    <Helmet>
      <title>ConciergePro Guest App Support 24/7</title>
      <meta
        name="keywords"
        content="documentation, support, messaging, guest, book, direct, booking, chat, ticket, how to, content"
      />
      <meta
        name="description"
        content="FAQs for your boutiqye hotel app made with ConciergePro - use chat for one to one support."
      />
    </Helmet>
  ),
  privacy: (
    <Helmet>
      <title>Privacy Statement</title>
      <meta
        name="keywords"
        content="guest, data, privacy, policy, GDPR"
      />
      <meta
        name="description"
        content="How we deal with privacy and data for hotels and resorts, clients and guests."
      />
    </Helmet>
  ),
  terms: (
    <Helmet>
      <title>Terms and Conditions</title>
      <meta
        name="keywords"
        content="terms, conditions, guest, app, resort, boutique, hotel"
      />
      <meta
        name="description"
        content="Our terms for using ConciergePro guest app builder."
      />
    </Helmet>
  ),
  gdpr: (
    <Helmet>
      <title>GDPR Information</title>
      <meta
        name="keywords"
        content="guest data, gdpr, statement"
      />
      <meta
        name="description"
        content="How we manege guest data and our gdpr policy."
      />
    </Helmet>
  ),
};
