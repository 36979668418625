import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, move } from 'ramda';

import update from 'immutability-helper';

import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import { withFormik } from 'formik';

import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';
import { ProductIdContext, UserRoleContext } from '@pro/web-common/containers/providers';

import SectionWithSortableSubsections from '@pro/web-common/components/section-with-sortable-subsections';
import LargeDialog from '@pro/web-common/components/large-dialog';
import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';
import ActionConfirmationModal from '@pro/web-common/components/action-confirmation-modal';
import MenuSectionContentEditor from '@pro/web-common/components/manual-section-content-editor';
import MenuConfigurator from '@pro/web-common/components/menu-configurator';
import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';

import { findIndexByProp } from '@pro/web-common/utils';
import { TIMETABLE_PAGE_EDITOR_ADD_INFO_TEXT, DEFAULT_TIMETABLE_CONTENT } from 'content/texts';

import { getSectionDefaultProps } from './utils';
import { styles } from './styles';


const TimetablePageEditor = React.memo(({ isOpened, onClose, isSubmitDisabled, pages, currentPageId, ...formikProps }) => {
  const { isDemo } = useContext(UserRoleContext);
  const productId = useContext(ProductIdContext);
  const classes = styles();

  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    dirty,
  } = formikProps;

  const [expandedSectionId, setExpandedSectionId] = useState(null);

  const [isCloseConfirmationModalVisible, setIsCloseConfirmationModalVisible] = useState(false);
  const [isDeletionConfirmationModalVisible, setIsDeletionConfirmationModalVisible] = useState(false);
  const [deletingSection, setDeletingSection] = useState(null);

  const onAddSection = useCallback(() => {
    setFieldValue('sections', values.sections.concat({
      ...getSectionDefaultProps(),
      subsections: [],
    }));
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSectionDataChange = useCallback(({ id, data }) => {
    const index = findIndexByProp(values.sections, 'id', id);
    const newSections = update(values.sections, { [index]: { $merge: data } });

    setFieldValue('sections', newSections);
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSectionDelete = useCallback(({ id }, forceDeletion) => {
    const index = findIndexByProp(values.sections, 'id', id);

    if (!forceDeletion) {
      const section = values.sections[index];
      setDeletingSection({ section });
      setIsDeletionConfirmationModalVisible(true);
    } else {
      const newSections = update(values.sections, { $splice: [[index, 1]] });
      setFieldValue('sections', newSections);
    }
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSectionVisibilityChange = useCallback(({ id }) => {
    const index = findIndexByProp(values.sections, 'id', id);
    const prevVisibility = values.sections[index].isVisible;
    const newSections = update(values.sections, { [index]: { $merge: { isVisible: !prevVisibility } } });

    setFieldValue('sections', newSections);
  }, [values.sections]);

  const closeDeletionConfirmationModal = useCallback(() => {
    setDeletingSection(null);
    setIsDeletionConfirmationModalVisible(false);
  }, []);

  const onSectionDeletionConfirm = useCallback(() => {
    const { section } = deletingSection;

    closeDeletionConfirmationModal();

    if (section) {
      onSectionDelete({ id: section.id }, true);
    }
  }, [deletingSection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setFieldValue('sections', move(oldIndex, newIndex, values.sections));
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = useCallback(() => {
    if (dirty) {
      setIsCloseConfirmationModalVisible(true);
    } else {
      onClose();
      setExpandedSectionId(null);
    }
  }, [dirty, onClose]);

  const onCloseConfirm = useCallback(() => {
    setIsCloseConfirmationModalVisible(false);
    onClose();
    setExpandedSectionId(null);
  }, [onClose]);

  const renderedSections = useMemo(() => (
    values.sections.map(({ id, isVisible, ...rest }, index) => (
      <SortableListItem
        key={id}
        index={index}
        isInModal
      >
        <SectionWithSortableSubsections
          sectionId={id}
          data={rest}
          expandedSectionId={expandedSectionId}
          toggleSection={(sectionId) => setExpandedSectionId(sectionId)}
          onSectionDataChange={(data) => onSectionDataChange({
            id,
            data,
          })}
          onSectionDelete={() => onSectionDelete({ id })}
          onSectionVisibilityChange={() => onSectionVisibilityChange({ id })}
          isSectionVisible={isVisible}
          sectionTitle="Diary Item"
          SectionContentEditor={MenuSectionContentEditor}
          isSubItemsEnabled={false}
        />
      </SortableListItem>
    ))
  ), [values.sections, expandedSectionId, onSectionDataChange, onSectionDelete, onSectionVisibilityChange]);

  useEffect(() => {
    if (isOpened) {
      resetForm();
    }
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LargeDialog
      open={isOpened}
      onClose={handleClose}
      disableEnforceFocus
    >
      <Box
        pl={2}
        pr={2}
      >
        <DialogTitle id="alert-dialog-title">
          {TIMETABLE_PAGE_EDITOR_ADD_INFO_TEXT}
        </DialogTitle>

        <form onSubmit={handleSubmit}>
          <MenuConfigurator
            initialThemeValues={values.stylesConfig}
            onThemeChange={(value) => setFieldValue('stylesConfig', value)}
            initialTrackTraceValues={values.trackTraceConfig}
            onTrackTraceChange={(value) => setFieldValue('trackTraceConfig', value)}
            isSubItemsEnabled={false}
            backgroundColorLabel="Title Background Colour"
            titleColorLabel="Title Text Colour"
          />

          <DialogContent className={classes.dialogContent}>
            <SortableList
              onSortEnd={onSortEnd}
              useDragHandle
              lockAxis="y"
            >
              {renderedSections}
            </SortableList>

            <Box className={classes.addSectionButtonContainer}>
              <Button
                variant="contained"
                color="default"
                startIcon={<AddIcon />}
                onClick={onAddSection}
              >
                Add Timetable
              </Button>
            </Box>
          </DialogContent>

          <Box className={classes.editorContainer}>
            <Box mb={2}>
              <Typography variant="caption">
                Timetable Footer
              </Typography>
            </Box>

            <CKEditorCustomized
              data={values.footerHtml}
              onChange={(data) => setFieldValue('footerHtml', data)}
              imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`}
            />
          </Box>

          <DialogActions className={classes.dialogActions}>
            <Button
              type="submit"
              size="small"
              color="primary"
              variant="contained"
              disabled={isSubmitDisabled}
            >
              Save
            </Button>
          </DialogActions>
        </form>

        {isDeletionConfirmationModalVisible && (
          <ActionConfirmationModal
            title="Deletion confirmation"
            text="Are you sure you want to delete section?"
            onClose={() => closeDeletionConfirmationModal()}
            onConfirm={onSectionDeletionConfirm}
          />
        )}

        {isCloseConfirmationModalVisible && (
          <ActionConfirmationModal
            title="Are you sure you want to exit?"
            text="You have not save your edits. Updates will be missed if you exit."
            onClose={() => setIsCloseConfirmationModalVisible(false)}
            onConfirm={onCloseConfirm}
          />
        )}
      </Box>
    </LargeDialog>
  );
});

TimetablePageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape(({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }))).isRequired,
  currentPageId: PropTypes.string.isRequired,
};

TimetablePageEditor.defaultProps = {};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const defaultValues = initialValues && !isEmpty(initialValues) && !isEmpty(initialValues.sections) ? initialValues : DEFAULT_TIMETABLE_CONTENT;
    const {
      sections = [
        {
          ...getSectionDefaultProps(),
          subsections: [],
        },
      ],
      stylesConfig = {},
      trackTraceConfig = {},
      footerHtml = '',
    } = defaultValues;

    const values = {
      sections,
      stylesConfig,
      trackTraceConfig,
      footerHtml,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values),
  enableReinitialize: true,
})(TimetablePageEditor);
