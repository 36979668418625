import { currencyValues } from '@pro/web-common/utils/currency';


export const FOOTER_TEXT_LINE_1 = '© 2021 Kappow Apps';
export const FOOTER_TEXT_LINE_2 = 'All rights reserved.';
export const MANAGER_FORM_CHOOSE_ITEM_LABEL = 'Choose properties';
export const MANUAL_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Guest Information for this Property';
export const MENU_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Menu for this Property [In Beta]';
export const TIMETABLE_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Timetable for this Property';
export const ALL_ITEMS_LABEL = 'All Properties';
export const PRODUCT_CONFIGURATION_FINISH_CONFIG_TEXT = 'Please add your Property name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_LABEL = 'Hotel / Resort / Attraction / Tour';
export const PRODUCT_CONFIGURATION_ITEM_NAME_PLACEHOLDER = 'Add Property Name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_REQUIRED_ERROR = 'Add Property name;';
export const PRODUCT_CONFIGURATION_PAGES_COUNT_ERROR = 'Add minimum four pages for your Hotel, Resort, Attraction or Tour;';
export const PRODUCT_FILTERS_SELECT_ITEM_LABEL = 'Select Property';
export const PRODUCTS_LISTING_NO_DATA_TEXT = 'You have not added a Property yet. Add your Hotel, Resort, Attraction or Tour name here.';
export const PRODUCT_ITEM_COPY_TEXT = 'Duplicate Property';
export const PRODUCT_ITEM_SUBSCRIBE_TEXT = 'Please subscribe to add more Propertys';
export const PRODUCT_ITEM_RESUME_ACCESS_TEXT = 'Use available Subscription to Resume access';
export const PRODUCT_PAGE_ADD_VERSIONS_TEXT = 'Add versions';
export const PRODUCT_PAGE_SUBSCRIBE_TO_ADD_VERSIONS_TEXT = 'You need a subscription to add language versions';
export const QUICK_MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added quick messages for the selected Property yet';
export const MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added messages for the selected Property yet';
export const RTF_PAGE_EDITOR_TITLE = 'Add text page for this Property';
export const ADMIN_PRODUCTS_TITLE = 'Property Hotel, Resort, Attraction or Tour';
export const ADMIN_PRODUCTS_ADD_PRODUCT_BUTTON_LABEL = 'Add a Property';
export const getAdminProductsAddMoreItemsText = (availableProducts) => `You can add ${availableProducts} more hotel/resort${availableProducts > 1 ? 's' : ''}`;
export const ADMIN_PRODUCTS_ADD_SUBSCRIPTION_TEXT = 'Add subscription to add a new Property';
export const ADD_FIRST_ITEM_TITLE = 'Add your first Property Hotel, Resort, Attraction or Tour';
export const ADD_PRODUCT_TITLE = 'Add Property';
export const ADD_PRODUCT_SUBTITLE = 'Add a hotel or resort + any support pages';
export const ADD_PRODUCT_ADD_NEW_PRODUCTS_TEXT = 'add new hotels and resorts';
export const ADMIN_HOME_TITLE = 'My Home';
export const ADMIN_HOME_SUBTITLE = 'Use the pencil/edit icon to update your content';
export const ADMIN_SUBSCRIPTIONS_TITLE = 'My Subscriptions';
export const ADMIN_SUBSCRIPTIONS_SUBTITLE = 'Upgrade for full features, or add more Properties in singles or packs of ten.';
export const ADMIN_SUBSCRIPTIONS_CONTACT_US_LABEL = 'Contact us for more than 10 Propertiess';
export const ADMIN_USERS_TITLE = 'My Users';
export const ADMIN_USERS_SUBTITLE = 'Users can be associated with a single or multiple Properties, and can manage content and messaging for them. Additional uers do not have access to Brand Configuration.';
export const EDIT_PRODUCT_SUBTITLE = 'Edit your content here (pencil/edit icon). Add and remove tabs, drag to re order. Use visibility to work on content before it goes live.';
export const EDIT_PRODUCT_LIMITED_ACCESS_NOTE = 'NOTE: You do not have an active subscription or trial period. To save updates please either renew your subscription (top right menu - My Subscriptions) or use the chat below to request a trial extension. Thank you!';
export const QUICK_MESSAGES_TITLE = 'Quick Messaging';
export const QUICK_MESSAGES_SUBTITLE = 'Manage quick messaging for your Properties. Quick messages are just simple Push Notifications.';
export const QUICK_MESSAGES_NO_ITEMS_NOTE = 'NOTE: You have not added any Properties yet or they have limited access at the moment. Contact support chat for more help.';
export const LOCAL_DIRECTORY_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Directories for Product';
export const PROFILE_HOME_TITLE = 'My Home';
export const PROFILE_HOME_SUBTITLE = 'All your products are listed here.';

export const DEFAULT_MANUAL_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Wifi Details',
      html: '<img src="https://firebasestorage.googleapis.com/v0/b/conciergepro-6f21a.appspot.com/o/products%2F668a4df1-7531-4880-afa7-19775b949814%2F490dbabd-c32d-43a4-80d7-5c7e488418d7?alt=media&token=590ff0e2-9abc-4ff5-9ebf-29a3e15b8d9a"><p>Digital guestbook section content</p>',
      image: null,
      subsections: [
        {
          id: 2,
          title: 'Digital guestbook sub section title',
          html: '<p>Digital guestbook sub section content</p>',
          image: null,
        },
      ],
    },
  ],
};
export const DEFAULT_MENU_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default menu title',
      html: '<p>Default menu content</p>',
      image: null,
      isVisible: true,
      subsections: [
        {
          id: 2,
          title: 'Default menu item title',
          html: '<p>Default menu item content</p>',
          image: null,
          currencyId: currencyValues[0].value,
          isVisible: true,
        },
      ],
    },
  ],
};

export const DEFAULT_TIMETABLE_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default Timetable Item - 9.00am',
      html: '<p>Default Timetable Item - 9.00am</p>',
      image: null,
      isVisible: true,
    },
  ],
};

export const DEFAULT_ISSUE_PAGE_CONTENT = {
  page: {
    title: 'Default page title',
    isVisible: true,
    html: "<p><span style=\"font-family:'Archivo Narrow';\">Default page content</span></p>",
    typeface: 'Source Sans Pro',
    subsections: [
      {
        titleBackgroundColor: {
          b: 41,
          g: 115,
          r: 45,
          a: 1,
        },
        id: 2,
        title: 'Default section title',
        typeface: 'IBM Plex Mono',
        image: null,
        isVisible: true,
        html: '<p><span style="font-family:Arvo;">Default section content</span></p><p>&nbsp;</p><figure class="image"><img src="https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4"></figure>',
      },
    ],
    image: {
      file: null,
      filePath: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
    },
    id: '14ec2616-6d4a-416c-90e3-b025362f9cb3',
    titleBackgroundColor: {
      g: 224,
      b: 173,
      r: 188,
      a: 1,
    },
  },
};


export const DEFAULT_MAGAZINE_ISSUES = [
  {
    startDate: {
      seconds: 1638350760,
      nanoseconds: 0,
    },
    coverTitlesMaxWidth: 100,
    coverTitleHtml: "<figure class=\"image image-style-align-right\"><img src=\"https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2F6a1cdaff-81d1-436a-9459-b4b46db19b1b.png?alt=media&token=3fad4976-a228-463e-8065-75f8f66c0ec3\"></figure><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><h1 style=\"text-align:right;\"><span style=\"background-color:hsl(0,0%,0%);color:hsl(0,0%,100%);font-family:'Changa One';\">These are my titles.</span></h1><h2 style=\"text-align:right;\"><span style=\"background-color:hsl(0,0%,0%);color:hsl(0,0%,100%);font-family:'Changa One';\">Another title.</span></h2>",
    coverImage: {
      url: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
      ref: 'Cover Image',
    },
    coverTitlesOpacity: 61,
    showIssueName: true,
    name: '<p class="ck-heading_paragraph--small" style="text-align:right;">Issue date/name</p>',
    endDate: null,
    pages: [
      {
        isExist: true,
        title: 'First page added',
        isVisible: true,
        html: "<p><span style=\"font-family:'Archivo Narrow';\">Default page content</span></p>",
        typeface: 'Source Sans Pro',
        subsections: [
          {
            titleBackgroundColor: {
              b: 41,
              g: 115,
              r: 45,
              a: 1,
            },
            id: 2,
            title: 'Default section title',
            typeface: 'IBM Plex Mono',
            image: null,
            isVisible: true,
            html: '<p><span style="font-family:Arvo;">Default section content</span></p><p>&nbsp;</p><figure class="image"><img src="https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4"></figure>',
          },
        ],
        image: {
          file: null,
          filePath: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
        },
        id: '14ec2616-6d4a-416c-90e3-b025362f9cb3',
        titleBackgroundColor: {
          g: 224,
          b: 173,
          r: 188,
          a: 1,
        },
      },
      {
        image: {
          file: null,
          filePath: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
        },
        id: '91fd90be-b603-4715-ab62-0ab597fa7ce5',
        title: 'Second page added',
        subsections: [
          {
            titleBackgroundColor: {
              g: 193,
              b: 193,
              a: 1,
              r: 215,
            },
            typeface: 'IBM Plex Serif',
            id: 2,
            title: 'This is section heading',
            image: null,
            html: "<h1>Here's a heading.</h1><p>Then some text.</p>",
            isVisible: true,
          },
        ],
        isExist: true,
        isVisible: true,
        typeface: 'Gelasio',
        html: "<p><span style=\"font-family:'Changa One';\">Default page content</span></p><p><span style=\"font-family:'Changa One';\">More content.</span></p><figure class=\"image\"><img src=\"https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fad0a986a-4b9b-4bb4-8023-2a376ab4ac03.png?alt=media&token=505ca9fb-1ef6-46ed-9f27-3a756c39fe16\"></figure>",
        titleBackgroundColor: {
          g: 71,
          r: 210,
          b: 187,
          a: 1,
        },
      },
    ],
  },
];

export const DEFAULT_MAGAZINE_CONFIG = {
  imprintLogo: {
    ref: 'Imprint Logo',
    url: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_b8220fa9-4ad3-4f0a-b5ba-dd58c6c6c4b2.png?alt=media&token=9d01344e-ae05-457a-95fe-3e9231c13753',
  },
  typeface: 'Cabin',
  imprintHtml: '<p class="ck-heading_paragraph--small">This is where the IMPRINT goes. Small copy and maybe a logo.<br>2021 © Kappow Apps Limited</p>',
  imprintBackgroundColor: {
    g: 237,
    a: 1,
    b: 11,
    r: 237,
  },
  logo: {
    ref: 'Logo',
    url: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_b8220fa9-4ad3-4f0a-b5ba-dd58c6c6c4b2.png?alt=media&token=9d01344e-ae05-457a-95fe-3e9231c13753',
  },
};
